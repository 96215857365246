$(document).ready(function () {

  let links = {};

  (window as any).jspcbDownload = function (resp) {
    if (resp.data) {
      links  = resp.data
    }
  }

  const isTest = window.location.href.includes('test')
  const script = document.createElement('script')
  script.src = `https://${isTest?'test-api':'api'}.shenglangyuyin.com/common-models/h5/app-package/latest?callback=jspcbDownload&appType=soundwave`
  script.type = 'text/javascript'
  document.body.appendChild(script)


  $('#android-btn').click(function () {
    if (links['X64']) { window.location.href = links['X64'].downloadURL}
  })

  $('#ios-btn').click(function () {
    if (links['IPHONE']) { window.location.href = links['IPHONE'].downloadURL }
  })

  function judgeClient() {
    let client = '';
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
      client = 'iOS';
    } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
      client = 'Android';
    } else {
      client = 'PC';
    }
    return client;
  }
  judgeClient()

  $('#ios_or_android-btn').click(function () {
    if (judgeClient() === 'iOS') {
      if (links['IPHONE']) { window.location.href = links['IPHONE'].downloadURL }
    } else {
      if (links['X64']) { window.location.href = links['X64'].downloadURL}
    }
  })



  // is ios
  var IS_IOS = /ipad|iphone|ipod/.test(navigator.userAgent.toLocaleLowerCase())
  if (IS_IOS) {
    $('body').addClass('ios')

    for (let index = 2; index < 6; index++) {
      $('.page' + index).remove()
    }
  } else {
    $('body').addClass('other')
  }

  var swiper = new Swiper(".swiper", {
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 30,
    mousewheel: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  swiper.on('slideChange', function () {
    $('body').attr('data-active', 'active-' + this.activeIndex)
  });


  $('.icon-down').click(function () {
    swiper.slideNext()
  })

  $('.mobile-icon-down').click(function () {
    swiper.slideNext()
  })
});
